import {Component, OnDestroy} from '@angular/core';
import {Subscription} from 'rxjs';
import {saveAs} from 'file-saver-es';
import {has, isArrayLike, isEmpty, isNil, negate, startsWith, trimStart} from 'lodash-es';
import {ToastrService} from 'ngx-toastr';
import {Line, Point, TimePeriod} from '../shared/migration/migration';
import {MigrationService} from '../shared/migration/migration.service';
import {FileUtils} from '../shared/file-utils';

@Component({
    selector: 'dna-sidebar',
    templateUrl: './sidebar.component.html',
    styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent implements OnDestroy {

    private static readonly WIP_DATA_FILE_PREFIX = 'wip_';

    public birthLocFile: File;
    public currentTimePeriod: TimePeriod;
    public mainName: string;
    public migrationFile: File;
    public timePeriods: TimePeriod[];
    public wipDataFile: File;
    private tempTimePeriods: TimePeriod[];
    private currentTimePeriodSub: Subscription;
    private migrationSub: Subscription;

    constructor(private toastrService: ToastrService,
                private migrationService: MigrationService) {
        this.currentTimePeriodSub = this.migrationService.currentTimePeriod.subscribe(time => this.currentTimePeriod = time);
        this.migrationSub = this.migrationService.migration.subscribe(migration => this.timePeriods = migration);
    }

    private static hasArrayProperty(data: any, prop: string): boolean {

        return has(data, prop) && isArrayLike(data[prop]);
    }

    private static trimPrefix(str: string, prefix: string): string {
        return startsWith(str, prefix)
            ? trimStart(str, prefix)
            : str;
    }

    ngOnDestroy(): void {
        this.currentTimePeriodSub.unsubscribe();
        this.migrationSub.unsubscribe();
    }

    public showFinal(event: Event): void {
        const target = event.target as HTMLInputElement;
        const checked = target.checked;

        this.migrationService.setShowFinal(checked);
    }

    public generateData(): void {
        const data = [JSON.stringify(this.migrationService.getFinalData())];
        const properties = {type: 'text/plain;charset=utf-8'};
        const blob = new Blob(data, properties);
        saveAs(blob, (this.mainName || 'data') + '.json');
    }

    public saveData(): void {
        const data = [JSON.stringify(this.migrationService.getAllData())];
        const properties = {type: 'text/plain;charset=utf-8'};
        const blob = new Blob(data, properties);
        saveAs(blob, SidebarComponent.WIP_DATA_FILE_PREFIX + (this.mainName || 'data') + '.json');
    }

    public birthLocFileChange(event: Event): void {
        this.birthLocFile = FileUtils.getFiles(event)
            .find(Boolean);
    }

    public migrationFileChange(event: Event): void {
        this.migrationFile = FileUtils.getFiles(event)
            .find(Boolean);
    }

    public wipDataFileChange(event: Event): void {
        this.wipDataFile = FileUtils.getFiles(event)
            .find(Boolean);
    }

    public uploadData(): void {

        if (!this.allNotNil(this.birthLocFile, this.migrationFile)) {

            this.toastrService.warning('Please ensure both birth location and migration files are provided.');
            return;
        }

        FileUtils.readFiles(
            this.migrationFile,
            this.birthLocFile
        ).then(([migration, birthLocations]) => {

            const filename = migration.file.name;
            this.mainName = filename.substring(0, filename.lastIndexOf('.')) || filename;

            this.handleMigrationReaderLoaded(migration.content);
            this.handleBirthLocReaderLoaded(birthLocations.content);
        });
    }

    public uploadWipData(): void {

        if (isNil(this.wipDataFile)) {

            this.toastrService.warning('Please ensure wip data file is provided.');
            return;
        }

        FileUtils.readFiles(this.wipDataFile).then(([wipData]) => {

            const wipFilename = wipData.file.name;
            const filename = SidebarComponent.trimPrefix(wipFilename, SidebarComponent.WIP_DATA_FILE_PREFIX);
            this.mainName = filename.substring(0, filename.lastIndexOf('.')) || filename;

            this.handleWipDataReaderLoaded(wipData.content);
        });
    }

    public allNotNil(...args: any[]): boolean {
        return args.every(negate(isNil));
    }

    private handleMigrationReaderLoaded(content: string | ArrayBuffer): void {
        const migration = FileUtils.parseJsonArray<{ time: number, lines: Line[] }>(content as string);

        if (isEmpty(migration)) {

            this.toastrService.warning('Migration data is empty. Please provide a valid data file.');
            return;
        }

        this.tempTimePeriods = migration.map(tp => new TimePeriod(tp.time, tp.lines, null));
    }

    private handleBirthLocReaderLoaded(content: string | ArrayBuffer): void {
        const birthLocations = FileUtils.parseJsonArray<{ time: number, points: Point[] }>(content as string);

        if ([birthLocations, this.tempTimePeriods].some(isEmpty)) {

            this.toastrService.warning('Birth locations data is empty. Please provide a valid data file.');
            return;
        }

        this.tempTimePeriods.forEach(timePeriod => {
            const birthTime = birthLocations.find(btp => btp.time === timePeriod.time);
            timePeriod.points = birthTime.points;
        });
        this.migrationService.setMigrationData(this.tempTimePeriods);
    }

    private handleWipDataReaderLoaded(content: string | ArrayBuffer): void {
        const jsonStr = content as string;
        const data = JSON.parse(jsonStr);

        const validWipData = SidebarComponent.hasArrayProperty(data, 'polygons')
            && SidebarComponent.hasArrayProperty(data, 'timePeriods');

        if (!validWipData) {

            this.toastrService.warning('WIP data is not valid. Please provide a valid WIP data file.');
            return;
        }

        this.migrationService.loadAllData(data);
    }
}
