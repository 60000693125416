import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { filter } from 'rxjs/operators';
import { point } from '@turf/helpers';
import inside from '@turf/inside';

import { Line, Point, PolygonLimit, TimePeriod } from './migration';

@Injectable()
export class MigrationService {
    currentTimePeriod: Observable<TimePeriod>;
    migration: Observable<TimePeriod[]>;
    polygons: Observable<PolygonLimit[]>;
    polygonAdded: Observable<GeoJSON.Feature<GeoJSON.Polygon>>;
    showFinal: Observable<boolean>;
    lineSelectedId: Observable<number>;

    private currentTimePeriodSubj: BehaviorSubject<TimePeriod> = new BehaviorSubject(null);
    private migrationSubj: BehaviorSubject<TimePeriod[]> = new BehaviorSubject(null);
    private polygonsSubj: BehaviorSubject<PolygonLimit[]> = new BehaviorSubject([]);
    private polygonAddedSubj: BehaviorSubject<GeoJSON.Feature<GeoJSON.Polygon>> = new BehaviorSubject(null);
    private showFinalSubj: BehaviorSubject<boolean> = new BehaviorSubject(false);
    private lineSelectedIdSubj: BehaviorSubject<number> = new BehaviorSubject(null);

    constructor() {
        this.currentTimePeriod = this.currentTimePeriodSubj.asObservable().pipe(filter(ct => ct !== null));
        this.migration = this.migrationSubj.asObservable().pipe(filter(m => m !== null));
        this.polygons = this.polygonsSubj.asObservable().pipe(filter(p => p !== null));
        this.polygonAdded = this.polygonAddedSubj.asObservable().pipe(filter(p => p !== null));
        this.showFinal = this.showFinalSubj.asObservable();
        this.lineSelectedId = this.lineSelectedIdSubj.asObservable();
    }

    public polygonDrawn(polygon: GeoJSON.Feature<GeoJSON.Polygon>) {
        this.polygonAddedSubj.next(polygon);
    }

    public addPolygonLimit(limit: PolygonLimit) {
        const polys = this.polygonsSubj.getValue();
        polys.push(limit);
        this.polygonsSubj.next(polys);
        this.recalcCurrentTimePeriod();
    }

    public deletePolygonLimit(polygonId: string | number) {
        const polys = this.polygonsSubj.getValue();
        this.polygonsSubj.next(polys.filter(p => p.polygon.id !== polygonId));
        this.recalcCurrentTimePeriod();
    }

    public getFinalData() {
        const timePeriods = this.migrationSubj.getValue();
        const results = [];
        timePeriods.forEach(timePeriod => {
            this.calcClusteredLines(timePeriod);
            this.calcClusteredPoints(timePeriod);
            this.calcLinesToKeep(timePeriod, this.polygonsSubj.getValue());
            this.calcPointToKeep(timePeriod, this.polygonsSubj.getValue());
            const points = timePeriod.clusterPoints.filter(p => p.keep).map(point => {
                return {
                    coords: [+point.coords[0].toFixed(5), +point.coords[1].toFixed(5)],
                    level: point.level
                };
            });
            const lines = timePeriod.clusterLines.filter(l => l.keep).map(line => {
                return {
                    coords: [[+line.coords[0][0].toFixed(5), +line.coords[0][1].toFixed(5)],
                        [+line.coords[1][0].toFixed(5), +line.coords[1][1].toFixed(5)]],
                    level: line.level
                };
            });
            results.push({
                time: timePeriod.time,
                clusterLvl: timePeriod.pointClusterLvl,
                points: points,
                lines: lines
            });
        });
        return results;
    }

    public getAllData() {
        return {
            polygons: this.polygonsSubj.getValue(),
            timePeriods: this.migrationSubj.getValue()
        };
    }

    public loadAllData(data: { polygons: Array<any>, timePeriods: Array<any> }): void {
        this.polygonsSubj.next(data.polygons);
        this.migrationSubj.next(data.timePeriods);
        this.currentTimePeriodSubj.next(data.timePeriods.find(Boolean));
    }

    public updatePolygonLimit(limit: PolygonLimit) {
        const polys = this.polygonsSubj.getValue();
        let limitToUpdate = polys.find(poly => poly.polygon.id === limit.polygon.id);
        limitToUpdate = limit;
        this.polygonsSubj.next(polys);
        this.recalcCurrentTimePeriod();
    }

    public setCurrentTimePeriod(timePeriod: TimePeriod) {
        this.calcClusteredLines(timePeriod);
        this.calcClusteredPoints(timePeriod);
        this.calcLinesToKeep(timePeriod, this.polygonsSubj.getValue());
        this.calcPointToKeep(timePeriod, this.polygonsSubj.getValue());
        this.currentTimePeriodSubj.next(timePeriod);
    }

    public setMigrationData(timePeriods: TimePeriod[]) {
        timePeriods.forEach(timePeriod => {
            this.calcClusteredLines(timePeriod);
            this.calcClusteredPoints(timePeriod);
        });
        this.migrationSubj.next(timePeriods);
        this.setCurrentTimePeriod(timePeriods[0]);
    }

    public updateTimePeriod(timePeriod: TimePeriod) {
        const timePeriods = this.migrationSubj.getValue();
        let timeToUpdate = timePeriods.find(time => time.time === timePeriod.time);
        timeToUpdate = timePeriod;
        this.migrationSubj.next(timePeriods);
        if (!!this.currentTimePeriodSubj.getValue() && this.currentTimePeriodSubj.getValue().time === timePeriod.time) {
            this.setCurrentTimePeriod(timePeriod);
        }
    }

    public setShowFinal(show: boolean) {
        this.showFinalSubj.next(show);
    }

    public setSelectedLine(id: number) {
        this.lineSelectedIdSubj.next(id);
    }

    private calcClusteredLines(timePeriod: TimePeriod) {
        const lines = timePeriod.lines;
        lines.sort((a, b) => b.count - a.count);
        const clusters: Line[] = [];
        if (lines.length) {
            let currId = 0;
            lines.forEach(line => {
                let clusterFound = false;
                clusters.forEach(cluster => {
                    if (
                        !clusterFound
                        && this.distance(line.coords[0], cluster.coords[0]) <= timePeriod.lineClusterLvl
                        && this.distance(line.coords[1], cluster.coords[1]) <= timePeriod.lineClusterLvl
                    ) {
                        clusterFound = true;
                        cluster.count += line.count;
                    }
                });
                if (!clusterFound) {
                    const cluster: Line = {
                        id:  +`${timePeriod.time}${currId}`,
                        coords: line.coords,
                        count: line.count,
                        keep: false,
                        level: 0
                    }
                    clusters.push(cluster);
                    currId++;
                }
            });
            const maxCount = clusters.map(c => c.count).reduce((max, count) => count > max ? count : max);
            clusters.forEach(cluster => cluster.level = Math.round(((cluster.count / maxCount) + 0.1) * 10));
        }
        timePeriod.clusterLines = clusters.filter(l => l.count >= timePeriod.minLineCount);
    }

    private calcClusteredPoints(timePeriod: TimePeriod) {
        const points = timePeriod.points;
        points.sort((a, b) => b.count - a.count);
        const clusters: Point[] = [];
        if (points.length > 0) {
            points.forEach(point => {
                let clusterFound = false;
                clusters.forEach(cluster => {
                    if (!clusterFound && this.distance(point.coords, cluster.coords) <= timePeriod.pointClusterLvl) {
                        clusterFound = true;
                        cluster.count += point.count;
                    }
                });
                if (!clusterFound) {
                    const cluster: Point = {
                        coords: point.coords,
                        count: point.count,
                        keep: false,
                        level: 0
                    }
                    clusters.push(cluster);
                }
            });
            const maxCount = clusters.map(c => c.count).reduce((max, count) => count > max ? count : max);
            clusters.forEach(cluster => cluster.level = Math.round(((cluster.count / maxCount) + 0.1) * 10));
        }
        timePeriod.clusterPoints = clusters;
    }

    private calcLinesToKeep(timePeriod: TimePeriod, polygons: PolygonLimit[]) {
        timePeriod.clusterLines.forEach((line) => {
            line.keep = false;
            if (!timePeriod.hideLines || !timePeriod.hideLines.includes(line.id)) {
                polygons.forEach(polygon => {
                    if (!line.keep) {
                        line.keep = (timePeriod.time >= polygon.startYear && timePeriod.time < polygon.endYear
                            && polygon.includeLines && inside(point(line.coords[0]), polygon.polygon));
                    }
                });
            }
        });
    }

    private calcPointToKeep(timePeriod: TimePeriod, polygons: PolygonLimit[]) {
        const endPoints = this.getEndpoints(timePeriod.time);
        timePeriod.clusterPoints.forEach(p => {
            p.keep = false;
            polygons.forEach(polygon => {
                if (!p.keep) {
                    p.keep = ((timePeriod.time >= polygon.startYear && timePeriod.time < polygon.endYear)
                        && inside(point(p.coords), polygon.polygon));
                }
            });
            if (!p.keep) {
                p.keep = this.inLineBoundary(p, endPoints);
            }
        });
    }

    private recalcCurrentTimePeriod() {
        if (this.currentTimePeriodSubj.getValue()) {
            const timePeriod = this.currentTimePeriodSubj.getValue();
            this.calcClusteredLines(timePeriod);
            this.calcClusteredPoints(timePeriod);
            this.calcLinesToKeep(timePeriod, this.polygonsSubj.getValue());
            this.calcPointToKeep(timePeriod, this.polygonsSubj.getValue());
            this.currentTimePeriodSubj.next(timePeriod);
        }
    }

    private getEndpoints(endYear: number) {
        const timePeriods = this.migrationSubj.getValue();
        if (!!timePeriods) {
            let points: number[][] = [];
            timePeriods
                .filter(time => time.time <= endYear)
                .forEach(timePeriod => {
                    points = points.concat(timePeriod.clusterLines
                        .filter(l => l.keep && l.count >= timePeriod.minLineCount)
                        .map(l => l.coords[1]));
                });
            return points;
        }
        return null;
    }

    private inLineBoundary(point: Point, endPoints: number[][]): boolean {
        return endPoints.findIndex(ep => this.distance(point.coords, ep) <= 2) >= 0;
    }

    private distance(coords1: number[], coords2: number[]): number {
        return Math.sqrt(Math.pow((coords1[0] - coords2[0]), 2) + Math.pow((coords1[1] - coords2[1]), 2));
    }

}
