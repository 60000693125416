import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
    name: 'pipeFunctionContext'
})
export class PipeFunctionContextPipe implements PipeTransform {

    public transform(handler: (this: any, ...args: any[]) => any, context?: any, ...args: any[]): any {

        return handler.apply(context, args);
    }
}
