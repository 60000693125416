import {NgModule} from '@angular/core';
import {FormsModule} from '@angular/forms';
import {BrowserModule} from '@angular/platform-browser';
import {NoopAnimationsModule} from '@angular/platform-browser/animations';
import {NgbModalModule} from '@ng-bootstrap/ng-bootstrap';

import {AppComponent} from './app.component';
import {MapComponent} from './map/map.component';
import {SidebarComponent} from './sidebar/sidebar.component';
import {MigrationService} from './shared/migration/migration.service';
import {AddPolygonModalComponent} from './add-polygon-modal/add-polygon-modal.component';
import {PolygonListComponent} from './polygon-list/polygon-list.component';
import {EditPolygonModalComponent} from './edit-polygon-modal/edit-polygon-modal.component';
import {TimePeriodDetailsComponent} from './sidebar/time-period-details/time-period-details.component';
import {ToastNoAnimationModule, ToastrModule} from 'ngx-toastr';
import {PipeFunctionPipe} from './shared/pipes/pipe-function.pipe';
import {PipeFunctionContextPipe} from './shared/pipes/pipe-function-context.pipe';
import {ResizableModule} from 'angular-resizable-element';
import {MatIconModule} from '@angular/material/icon';
import {MatButtonToggleModule} from '@angular/material/button-toggle';
import {MatTooltipModule} from '@angular/material/tooltip';
import {MatButtonModule} from '@angular/material/button';

@NgModule({
    declarations: [
        AppComponent,
        MapComponent,
        SidebarComponent,
        AddPolygonModalComponent,
        PolygonListComponent,
        EditPolygonModalComponent,
        TimePeriodDetailsComponent,
        PipeFunctionPipe,
        PipeFunctionContextPipe
    ],
    imports: [
        BrowserModule,
        NoopAnimationsModule,
        ToastNoAnimationModule.forRoot(),
        ToastrModule.forRoot(),
        FormsModule,
        NgbModalModule,
        ResizableModule,
        MatIconModule,
        MatButtonModule,
        MatButtonToggleModule,
        MatTooltipModule
    ],
    providers: [MigrationService],
    bootstrap: [AppComponent]
})
export class AppModule {
}
