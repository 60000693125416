<ng-template let-modal>
    <div class="modal-header">
        <h4 class="modal-title">
            Edit Polygon Limit
        </h4>
        <button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')"></button>
    </div>
    <div class="modal-body">
        <form #editPolygon="ngForm">
            <div class="form-group">
                <label for="name">Name</label>
                <input class="form-control" type="string" id="name" required [(ngModel)]="polygonLimit.name"
                       name="name">
            </div>
            <div class="form-row">
                <div class="col">
                    <div class="form-group">
                        <label for="startYear">Start Year</label>
                        <input class="form-control" type="number" id="startYear" required
                               [(ngModel)]="polygonLimit.startYear"
                               name="startYear" min="1500" max="1925" step="25" value="1700">
                    </div>
                </div>
                <div class="col">
                    <div class="form-group">
                        <label for="endYear">End Year</label>
                        <input class="form-control" type="number" id="endYear" required
                               [(ngModel)]="polygonLimit.endYear"
                               name="endYear" min="1700" max="2025" step="25" value="1700">
                    </div>
                </div>
            </div>
            <div class="form-group form-check">
                <input class="form-check-input" type="checkbox" id="includeLines"
                       [(ngModel)]="polygonLimit.includeLines" name="includeLines">
                <label class="form-check-label" for="includeLines">Include Lines</label>
            </div>
        </form>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-outline-secondary" (click)="modal.dismiss()">Cancel</button>
        <button type="button"
                class="btn btn-primary"
                [disabled]="editPolygon.invalid"
                (click)="saveChanges(modal)">
            Save
        </button>
    </div>
</ng-template>
