<div class="container-fluid">
    <div class="row">
        <div class="col-lg-auto col-md-auto col-sm-12 border-end border-bottom p-0">
            <mat-button-toggle class="no-padding rounded-0" data-bs-toggle="collapse" data-bs-target="#leftSidebar"
                               aria-expanded="true" aria-controls="leftSidebar" checked="true" matTooltip="Project">
                <mat-icon>folder</mat-icon>
            </mat-button-toggle>
        </div>
        <div class="col-lg-3 col-md-3 mw-sm-mw-12 left-sidebar position-relative p-0 collapse show" id="leftSidebar"
             mwlResizable [ngStyle]="leftSidebarStyle" (resizeEnd)="onLeftSidebarResizeEnd($event)">
            <dna-sidebar></dna-sidebar>
            <div class="resize-handle-right top-0" mwlResizeHandle [resizeEdges]="{ right: true }"></div>
        </div>
        <main class="col-lg col-md map-container p-0">
            <dna-map></dna-map>
        </main>
    </div>
</div>
