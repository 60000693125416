import {isArrayLike} from 'lodash-es';

export class FileUtils {

    public static getFiles(event: Event): Array<File> {
        const target = event.target as HTMLInputElement;
        const files = target.files as FileList;

        return Array.from(files);
    }

    public static async readFiles(...files: File[]): Promise<Array<{ file: File, content: string | ArrayBuffer }>> {
        const filePromises: Array<Promise<{ file: File, content: string | ArrayBuffer }>> = files.map(file => {

            const reader = new FileReader();

            return new Promise(resolve => {
                reader.onload = (e) => resolve({file: file, content: e.target.result});
                reader.readAsText(file);
            });
        });

        return await Promise.all(filePromises);
    }

    public static parseJsonArray<T>(json: string): Array<T> {
        const obj = JSON.parse(json);

        if (!isArrayLike(obj)) {

            throw new Error('Can not process unrecognized JSON structure');
        }

        return obj as Array<T>;
    }
}
