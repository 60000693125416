<div class="detailHeader text-bg-info border p-3" (click)="selectTimePeriod()">
    <div class="font-weight-bold">{{ timePeriod.time }}</div>
</div>
<div class="detailsContent p-2 border" *ngIf="selected">
    <div class="mb-2">
        Line Count: Avg: {{ lineCountAvg }}; Max: {{ lineCountMax }}
    </div>
    <div class="mb-2 row">
        <label for="minLineCount" class="col-sm-6 col-form-label">Min Line Count</label>
        <div class="col-sm-6">
            <input type="number" class="form-control" id="minLineCount" [(ngModel)]="timePeriod.minLineCount"/>
        </div>
    </div>
    <div class="mb-2 row">
        <label for="lineClusterDist" class="col-sm-6 col-form-label">Line Cluster Dist</label>
        <div class="col-sm-6">
            <input type="number" class="form-control" id="lineClusterDist" [(ngModel)]="timePeriod.lineClusterLvl"/>
        </div>
    </div>
    <div class="mb-2 row">
        <label for="pointClusterDist" class="col-sm-6 col-form-label">Point Cluster Dist</label>
        <div class="col-sm-6">
            <input type="number" class="form-control" id="pointClusterDist" [(ngModel)]="timePeriod.pointClusterLvl"/>
        </div>
    </div>
    <div class="mb-2 row" *ngIf="lineIdSelected">
        <div class="col-sm-8">
            Line Selected: {{ lineIdSelected }}
        </div>
        <div class="col-sm-4 text-end">
            <button class="btn btn-sm btn-danger" (click)="hideLine()" type="button"><i class="fas fa-trash-alt"></i>
            </button>
        </div>
    </div>
    <ul class="mb-2 list-group" *ngIf="timePeriod.hideLines && timePeriod.hideLines.length">
        <li class="list-group-item d-flex justify-content-between align-items-center"
            *ngFor="let line of timePeriod.hideLines">
            <div>
                ID: {{ line }}
            </div>
            <button class="btn btn-sm btn-primary" (click)="showLine(line)" type="button"><i class="fas fa-eye"></i>
            </button>
        </li>
    </ul>
    <button type="button" class="btn btn-primary" (click)="update()">Update</button>
</div>
