export class Line {
    id: number;
    coords: number[][];
    count: number;
    level: number;
    keep = false;
}

export class Point {
    coords: number[];
    count: number;
    level: number;
    keep = false;
}

export class TimePeriod {
    time: number;
    lines: Line[];
    points: Point[];
    clusterLines: Line[];
    clusterPoints: Point[];
    minLineCount = 5;
    lineClusterLvl = 0.25;
    pointClusterLvl = 0.5;
    hideLines: number[];

    constructor(time: number, lines: Line[], points: Point[]) {
        this.time = time;
        this.lines = lines;
        this.points = points;
    }
}

export class PolygonLimit {
    name: string;
    polygon: GeoJSON.Feature<GeoJSON.Polygon>;
    startYear: number;
    endYear: number;
    includeLines = true;
    centerPoint: number[];
}
