import { Component, Input, OnInit } from '@angular/core';
import { TimePeriod } from '../../shared/migration/migration';
import { MigrationService } from '../../shared/migration/migration.service';
import {Subscription} from 'rxjs';

@Component({
    selector: 'dna-time-period-details',
    templateUrl: './time-period-details.component.html',
    styleUrls: ['./time-period-details.component.scss']
})
export class TimePeriodDetailsComponent implements OnInit {
    @Input() timePeriod: TimePeriod;
    @Input() selected: boolean;

    lineCountAvg: number;
    lineCountMax: number;
    lineIdSelected: number;

    private subscription = new Subscription();

    constructor(private migrationService: MigrationService) {
        this.subscription.add(this.migrationService.lineSelectedId.subscribe(id => this.lineIdSelected = id));
    }

    ngOnInit() {
        this.lineCountAvg = this.getLineCountAvg();
        this.lineCountMax = this.getLineCountMax();
    }

    hideLine() {
        if (this.lineIdSelected && (!this.timePeriod.hideLines || !this.timePeriod.hideLines.includes(this.lineIdSelected))) {
            if (!this.timePeriod.hideLines) {
                this.timePeriod.hideLines = [];
            }
            this.timePeriod.hideLines.push(this.lineIdSelected);
            this.migrationService.updateTimePeriod(this.timePeriod);
        }
    }

    showLine(line: number) {
        if (line && this.timePeriod.hideLines) {
            this.timePeriod.hideLines = this.timePeriod.hideLines.filter(id => id !== line);
            this.migrationService.updateTimePeriod(this.timePeriod);
        }
    }

    selectTimePeriod() {
        this.migrationService.setCurrentTimePeriod(this.timePeriod);
    }

    update() {
        if (this.timePeriod.minLineCount < 5) {
            this.timePeriod.minLineCount = 5;
        }
        if (this.timePeriod.pointClusterLvl < 0.5) {
            this.timePeriod.pointClusterLvl = 0.5;
        }
        this.migrationService.updateTimePeriod(this.timePeriod);
        this.lineCountAvg = this.getLineCountAvg();
        this.lineCountMax = this.getLineCountMax();
    }

    private getLineCountAvg() {
        if (this.timePeriod.clusterLines.length > 0) {
            const countSum = this.timePeriod.clusterLines.map(l => l.count)
                .reduce((sum, count) => sum += count);
            return countSum / this.timePeriod.clusterLines.length;
        }
        return 0;
    }

    private getLineCountMax() {
        if (this.timePeriod.clusterLines.length > 0) {
            return this.timePeriod.clusterLines.map(l => l.count)
                .reduce((max, count) => count > max ? count : max);
        }
        return 0;
    }
}
