import { Component, EventEmitter, OnDestroy, Output } from '@angular/core';
import { Subscription } from 'rxjs';

import { MigrationService } from '../shared/migration/migration.service';
import { PolygonLimit } from '../shared/migration/migration';

@Component({
    selector: 'dna-polygon-list',
    templateUrl: './polygon-list.component.html',
    styleUrls: ['./polygon-list.component.scss']
})
export class PolygonListComponent implements OnDestroy {
    @Output() editPolygon = new EventEmitter();

    polygonLimits: PolygonLimit[];

    polygonsSub: Subscription;

    constructor(private migrationService: MigrationService) {
        this.polygonsSub = this.migrationService.polygons.subscribe(polygons => this.polygonLimits = polygons);
    }

    ngOnDestroy() {
        this.polygonsSub.unsubscribe();
    }

    deleteLimit(limit: PolygonLimit) {
        this.migrationService.deletePolygonLimit(limit.polygon.id);
    }

    editLimit(limit: PolygonLimit) {
        this.editPolygon.emit(limit);
    }
}
