import { Component, OnDestroy, TemplateRef, ViewChild } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Subscription } from 'rxjs';
import centerOfMass from '@turf/center-of-mass';

import { MigrationService } from '../shared/migration/migration.service';
import { PolygonLimit } from '../shared/migration/migration';

@Component({
    selector: 'dna-add-polygon-modal',
    templateUrl: './add-polygon-modal.component.html',
    styleUrls: ['./add-polygon-modal.component.scss']
})
export class AddPolygonModalComponent implements OnDestroy {
    @ViewChild(TemplateRef, {static: true}) modalContent;
    polygonLimit: PolygonLimit;

    private polygonAddedSub: Subscription;

    constructor(private modalService: NgbModal,
                private migrationService: MigrationService) {
        this.polygonAddedSub = this.migrationService.polygonAdded.subscribe(polygon => {
            this.polygonLimit = new PolygonLimit();
            this.polygonLimit.polygon = polygon;
            this.polygonLimit.centerPoint = centerOfMass(polygon).geometry.coordinates;
            this.modalService.open(this.modalContent, {size: 'lg', backdrop: 'static'});
        });
    }

    ngOnDestroy() {
        this.polygonAddedSub.unsubscribe();
    }

    addPolygonLimit(modal: NgbActiveModal) {
        if (this.polygonLimit.startYear && this.polygonLimit.endYear
            && this.polygonLimit.startYear < this.polygonLimit.endYear) {
            this.migrationService.addPolygonLimit(this.polygonLimit);
            modal.dismiss('add polygon');
        }
    }
}
