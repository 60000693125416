import {Component} from '@angular/core';
import {ResizeEvent} from 'angular-resizable-element';

@Component({
    selector: 'dna-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})
export class AppComponent {

    public leftSidebarStyle: object = {
        width: '432px'
    };

    public onLeftSidebarResizeEnd(event: ResizeEvent): void {

        this.leftSidebarStyle = {
            width: `${event.rectangle.width}px`
        };
    }
}
