<div *ngIf="timePeriods else noData" class="h-100 d-flex flex-column flex-nowrap border-end">

    <div class="h-240px d-flex flex-column flex-nowrap border-bottom mt-1">

        <ul class="nav nav-tabs" role="tablist">
            <li class="nav-item">
                <button [class.active]="true" type="button" class="nav-link py-0" role="tab">
                    File
                </button>
            </li>
        </ul>

        <div class="flex-grow-1 tab-content overflow-hidden">
            <div class="h-100 tab-pane p-3 fade overflow-auto"
                 [ngClass]="{'show active' : true}"
                 role="tabpanel">
                <div class="row mb-2">
                    <label for="mainName" class="col-sm-auto col-form-label">Name</label>
                    <div class="col-sm">
                        <input type="text" class="form-control" id="mainName" required [(ngModel)]="mainName">
                    </div>
                </div>
                <div class="d-flex gap-2 mb-2">
                    <button type="button" class="w-100 btn btn-sm btn-primary" (click)="saveData()">Save WIP</button>
                    <button type="button" class="w-100 btn btn-sm btn-success" (click)="generateData()">Download Final
                    </button>
                </div>
                <div class="form-check form-check-reverse">
                    <input type="checkbox" class="form-check-input" id="showFinal" name="showFinal"
                           (change)="showFinal($event)">
                    <label class="form-check-label" for="showFinal">Show Final</label>
                </div>
            </div>
        </div>

    </div>

    <main class="flex-grow-1 overflow-hidden vstack mt-1">

        <ul class="nav nav-tabs">
            <li class="nav-item">
                <button [class.active]="true" type="button" class="nav-link py-0" role="tab">
                    Dataset
                </button>
            </li>
        </ul>

        <div class="flex-grow-1 tab-content overflow-hidden vstack">

            <div class="flex-grow-1 overflow-auto p-1">
                <dna-time-period-details *ngFor="let timePeriod of timePeriods"
                                         [timePeriod]="timePeriod"
                                         [selected]="timePeriod.time === currentTimePeriod?.time">
                </dna-time-period-details>
                <dna-polygon-list (editPolygon)="editModal.open($event)"></dna-polygon-list>
            </div>
        </div>
    </main>

</div>

<ng-template #noData>
    <div class="container-fluid">
        <div>
            <h1>Upload Data:</h1>
        </div>
        <div class="mb-2">
            <label for="migrationFile" class="form-label">Migration JSON</label>
            <input class="form-control" id="migrationFile" type="file"
                   (change)="migrationFileChange($event)" accept=".json"/>
        </div>
        <div class="mb-2">
            <label for="birthLocFile" class="form-label">Birth Location JSON</label>
            <input class="form-control" id="birthLocFile" type="file"
                   (change)="birthLocFileChange($event)" accept=".json"/>
        </div>
        <div>
            <button type="button" class="btn btn-primary" (click)="uploadData()"
                    *ngIf="allNotNil | pipeFunctionContext:this:migrationFile:birthLocFile">
                Upload
            </button>
        </div>
        <div>
            <h2>Or Upload WIP Data:</h2>
        </div>
        <div class="mb-2">
            <label for="wipDataFile" class="form-label">WIP Data JSON</label>
            <input class="form-control" id="wipDataFile" type="file"
                   (change)="wipDataFileChange($event)" accept=".json"/>
        </div>
        <div>
            <button type="button" class="btn btn-primary" (click)="uploadWipData()"
                    *ngIf="allNotNil | pipeFunctionContext:this:wipDataFile">
                Upload WIP
            </button>
        </div>
    </div>
</ng-template>

<dna-add-polygon-modal></dna-add-polygon-modal>
<dna-edit-polygon-modal #editModal></dna-edit-polygon-modal>
