import { Component, TemplateRef, ViewChild } from '@angular/core';

import { PolygonLimit } from '../shared/migration/migration';
import { MigrationService } from '../shared/migration/migration.service';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
    selector: 'dna-edit-polygon-modal',
    templateUrl: './edit-polygon-modal.component.html',
    styleUrls: ['./edit-polygon-modal.component.scss']
})
export class EditPolygonModalComponent {
    @ViewChild(TemplateRef, {static: true}) modalContent;

    polygonLimit: PolygonLimit;

    constructor(private modalService: NgbModal,
                private migrationService: MigrationService) {
    }

    open(polygonLimit: PolygonLimit) {
        this.polygonLimit = polygonLimit;
        this.modalService.open(this.modalContent, {size: 'lg', backdrop: 'static'});
    }

    saveChanges(modal: NgbActiveModal) {
        if (this.polygonLimit.startYear && this.polygonLimit.endYear
            && this.polygonLimit.startYear < this.polygonLimit.endYear) {
            this.migrationService.updatePolygonLimit(this.polygonLimit);
            modal.dismiss('save polygon');
        }
    }
}
