<div class="mt-3" *ngIf="!!polygonLimits">
    <div class="card mb-2" *ngFor="let limit of polygonLimits">
        <header class="card-header">
            {{ limit.name || limit.polygon.id }}
        </header>
        <div class="card-body">
            <div>{{limit.startYear}}-{{limit.endYear}}</div>
            <div>Include Lines: {{limit.includeLines + ''}};</div>
            <div class="mt-2">
                <button type="button" class="btn btn-sm btn-danger me-1" (click)="deleteLimit(limit)"><i class="fas fa-trash-alt"></i> Delete</button>
                <button type="button" class="btn btn-sm btn-warning" (click)="editLimit(limit)"><i class="fas fa-edit"></i> Edit</button>
            </div>
        </div>
    </div>
</div>
